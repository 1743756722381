<template>
  <v-app id="app">
    <router-view/>
    <v-container>
      

      
          <v-btn
              v-if="estaActivo"
              color="pink"
              class="btnSalir"
              dark
              small
              absolute
              top
              right
             
              @click="cerrarSesion()"
            >
              <small>Cerrar Sesion</small>
            </v-btn>
    </v-container>  


    <notifications group="foo" position="top center" />
    <notifications group="errors" position="top center" />

    <!-- btn para regresar a la vista anterior -->
      <v-btn
      v-if="estaActivo"
        class="mx-2 btnReturn"
        fab
        dark
        @click="returnpage()"
        color="red"
      >
        <v-icon dark>
          mdi-arrow-left
        </v-icon>
      </v-btn>


    </v-app>
</template>


<script>
import axios from 'axios';
import store from "./store";
import { mapActions, mapGetters } from "vuex";



export default {
   data() {
    return {
      corte: "",
      ventas_efectivo: 0.0,    
      devolucion_efectivo: 0,
      fondo: 0,
      entradas_efectivo: [],
      devoluciones: [],
      total_entradas_efectivo: 0,
      total_salidas_efectivo: 0,
      total_devolucion_efectivo: 0,
      salidas_efectivo: [],      
      efectivo_caja: 0,      
      
    }
  },

  
  methods:{
    ...mapActions(["cerrarSesion", "leerToken"]),
    getcorteactual() {
            axios
              .get("api/corteactual/" + store.state.usuarioDB.data._id)
              .then((response) => {
              
                if (response.data.length === 0) {
            
                  alert(
                    "Necesitas Abrir un nuevo Corte para poder registrar Ventas"
                  )
               
                      // this.$router.push({ name: "newcorte" });
                     // this.$router.push({ path: './newcorte' })
                      this.$router.push({ name: 'newcorte'})
                } else {
                  this.corte = response.data[0]._id;
                  this.fondo = response.data[0].fondo;
                  this.getventasefectivo();
                  this.getentradasefectivo();
                  this.getsalidasefectivo();
                  this.getdevoluciones();
                  setTimeout(() => {
                    this.calcularefectivoencaja();
                  }, 1000);
                  
                }
              })
              .catch((e) => console.log(e));
      },
   
      returnpage(){
        window.history.go(-1); return false;
      }

    },
    computed: {
            //  ...mapGetters(["estaActivo"]),
            ...mapGetters(["estaActivo"])
            
       },
 
      created() {
        this.leerToken();
        
      },
      mounted(){
       if(store.state.usuarioDB.length > 0){
        if(store.state.usuarioDB.data.role==="ADMIN" || store.state.usuarioDB.data.role === "CASHIER"){this.getcorteactual()}
      }


       },
    


}

</script>


<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1 {
  color: orange;
}
.btnReturn{
  bottom:10px;left:10px;position:fixed;z-index:9999;
}
.btnSalir{
  top:10px;right:10px;position:fixed;z-index:9999;
}



.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
