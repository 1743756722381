<template>
    <v-container>
        <h1>Menu Principal</h1><br>
        <v-row>
            <!-- punto de venta -->
            <v-col cols="12" md="4" v-if="role === 'CASHIER' || role === 'ADMIN'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'pos'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Punto de Venta
                            </v-list-item-title>
                            <v-list-item-subtitle>Comienza a vender aqui!</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/pos.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'products'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                               Productos
                            </v-list-item-title>
                            <v-list-item-subtitle>Registra y Edita tus Productos o Servicios!</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/products.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="role === 'CASHIER' || role === 'ADMIN'" >
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'customers'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                               Clientes
                            </v-list-item-title>
                            <v-list-item-subtitle>Da de Alta y Edita a tus Cliente</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/customer.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>   
            <v-col cols="12" md="4" v-if="role === 'CASHIER' || role === 'ADMIN'" >
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'customersinvoices'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                               Clientes Facturacion
                            </v-list-item-title>
                            <v-list-item-subtitle>Da de Alta y Edita a tus Cliente de Facturacion</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/customer.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>            
            <v-col cols="12" md="4" v-if="role === 'CASHIER' || role === 'ADMIN'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'invoices'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Facturas
                            </v-list-item-title>
                            <v-list-item-subtitle>Revisa y Reimprime Facturas Realizadas</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/facturas.webp" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="role === 'ADMIN'" >
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'proveedores'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Proveedores
                            </v-list-item-title>
                            <v-list-item-subtitle>Registrar y Edita tus Proveedores</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/proveedores.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="role === 'ADMIN'" >
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'ventas'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Ventas 
                            </v-list-item-title>
                            <v-list-item-subtitle>Revisa Lista de Ventas por Corte O dia</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/pos.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="role === 'STORER' || role === 'ADMIN'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'almacen'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Almacen
                            </v-list-item-title>
                            <v-list-item-subtitle>!</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/almacen.jpeg" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>


            <v-col cols="12" md="4" v-if="role === 'ADMIN'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'reports'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Reportes
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                Visualiza reportes de ventas, cortes de caja, Etc.  
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/reports.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="role === 'CHECADOR' || role === 'ADMIN' || role === 'STORER' || role === 'CASHIER'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'checador'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Checador de Precios
                            </v-list-item-title>
                            <v-list-item-subtitle>Checa los precios de los Articulos!</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/pos.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
            </v-col>

            <v-col cols="12" md="4" v-if="role === 'ADMIN'">
                <v-card
                    elevation="11"
                    outlined
                    shaped
                    @click="$router.push({ name: 'settings'})"
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                            
                            <v-list-item-title class="text-h5 mb-1">
                                Configuraciones
                            </v-list-item-title>
                            <v-list-item-subtitle>Realiza las configuracion Basicas del Punto de Venta</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                        >
                            <img src="../assets/img/settings.png" alt="">
                        </v-list-item-avatar>
                        </v-list-item>

                </v-card>
                
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import store from "../store";
export default {
    data(){
        return {
            role: store.state.usuarioDB.data.role
        }
    },
    mounted(){
           if(store.state.usuarioDB.data.role === "CHECADOR"){
             this.$router.push({ name: 'checador'})
           }
    }   
}

</script>